<template>
  <div
    class="card mb-3 border-0 mx-2 mx-md-0"
    style="background-color: #f6f9fc"
  >
    <playlist-delete-modal :playlist_id="playlist.id"> </playlist-delete-modal>
    <div class="playlist-image card-img-top" @click="showPlaylist">
      <AdvancedImage
        :cldImg="playlistImg"
        :plugins="imgPlugins"
        style="height: 245px"
        class="rounded-3 text-center w-100"
        v-if="playlist.image_path"
      />
      <div class="playlist-text text-primary">
        <i class="bi bi-play fs-1"></i>
      </div>
    </div>
    <div class="g-0 card-body">
      <div class="row card-text justify-content-start">
        <div class="col-3">
          <profile-image
            :user="playlist.user"
            :size="62"
            class="me-3"
          ></profile-image>
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-10 text-start">
              <div class="playlist-title text-truncate">
                {{ playlist.name }}
              </div>
              <div>
                <router-link
                  :to="`/profile/${playlist.user.id}`"
                  style="text-decoration: none; color: inherit"
                >
                  <small class="text-muted playlist-subtitle">
                    {{ playlist.user.first_name }}
                    {{ playlist.user.last_name }}
                    <i
                      class="bi bi-patch-check-fill text-success ms-1"
                      v-if="playlist.user.email_verified_at != null"
                    ></i>
                  </small>
                </router-link>
              </div>
              <div>
                <small class="text-muted playlist-bottom-title">
                  {{ moment(playlist.created_at).fromNow() }}
                </small>
              </div>
            </div>
            <div class="col-2 text-end">
              <div class="dropdown">
                <button
                  class="option-btn btn p-0"
                  type="button"
                  id="playlistActions"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-three-dots-vertical"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li v-if="showOption(playlist)">
                    <button
                      class="dropdown-item"
                      data-bs-toggle="modal"
                      :data-bs-target="'#playlistDeleteModal' + playlist.id"
                    >
                      Delete Playlist
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import { mapState } from "vuex"
import { getCloudinaryPId } from "@/utils/misc"
import PlaylistDeleteModal from "@/components/dashboard/playlist/PlaylistDeleteModal.vue"
import ProfileImage from "@/components/profile/ProfileImage.vue"
import { isAuth } from "@/utils/misc"

import {
  AdvancedImage,
  lazyload,
  responsive,
  placeholder
} from "@cloudinary/vue"

export default {
  name: "PlaylistTrack",
  props: { playlist: Object },
  components: { PlaylistDeleteModal, ProfileImage, AdvancedImage },
  data() {
    const imgPlugins = [
      lazyload(),
      responsive(),
      placeholder({ mode: "pixelate" })
    ]
    return {
      imgPlugins
    }
  },
  computed: {
    ...mapState("user", ["user"]),
    playlistImg() {
      const img = this.cld.image(getCloudinaryPId(this.playlist.image_path))
      return img
    }
  },
  methods: {
    moment: moment,
    getCloudinaryPId: getCloudinaryPId,
    showPlaylist() {
      this.$router.push(`/playlists/${this.playlist.id}`)
    },
    showOption(item) {
      if (isAuth() && item.user.id == this.user?.id) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped lang="scss">
.playlist-text {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.playlist-image {
  max-width: 400px;
  cursor: pointer;
  position: relative;
  text-align: center;
  border-radius: 15px;
}

.playlist-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.playlist-subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.playlist-bottom-title {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.playlist-image:hover {
  filter: grayscale(100%);

  .playlist-text {
    opacity: 1;
  }

  .dropdown-menu:not(:has(li)) {
    display: none;
  }
}
</style>
