<template>
  <div
    class="modal fade"
    :id="'playlistDeleteModal' + id"
    tabindex="-1"
    aria-labelledby="playlistDeleteModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h4 class="modal-title text-sm" id="playlistModalLabel">
            Delete Playlist
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <form @submit.prevent="submit">
          <div class="modal-body">
            <div class="mb-3">
              {{ message }}
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Delete
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "PlaylistDeleteModal",
  props: {
    playlist_id: Number
  },
  data() {
    return {
      id: null,
      message: "Are you sure you want to delete this playlist?"
    }
  },
  created() {
    this.id = this.playlist_id
  },
  methods: {
    ...mapActions("playlist", ["deletePlaylist"]),
    submit() {
      this.deletePlaylist({ id: this.id })
    }
  },
  watch: {
    playlist_id(data) {
      this.id = data
    }
  }
}
</script>

<style scoped>
.modal-title {
  font-size: 1rem;
}
</style>
