<template>
  <div class="py-5 my-5 overflow-hidden mx-md-4 mx-lg-8">
    <div class="row">
      <div class="col-md-12">
        <PlaylistFeeds />
      </div>
    </div>
  </div>
</template>

<script>
import PlaylistFeeds from "@/components/playlist/PlaylistFeeds.vue"
export default {
  name: "UserPlaylist",
  components: { PlaylistFeeds }
}
</script>
