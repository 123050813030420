<template>
  <div
    class="modal fade"
    id="playlistModal"
    tabindex="-1"
    aria-labelledby="playlistModalLabel"
    aria-hidden="true"
    ref="playlistModal"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h5 class="modal-title">Create playlist</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitPlaylist">
            <div class="row g-3 mb-3">
              <div class="col-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Playlist Name"
                  aria-label="Name"
                  required
                  v-model="playlistObj.name"
                />
              </div>
              <div class="col-12">
                <textarea
                  class="form-control overflow-auto"
                  ref="playlistDescription"
                  v-model="playlistObj.description"
                  placeholder="Add description"
                  rows="6"
                  required
                  maxlength="1024"
                  @input="resize"
                ></textarea>
              </div>
              <div style="position: relative" v-if="playlistObj.image_path">
                <i
                  class="bi bi-x-circle-fill fs-4 rounded-circle px-1 close-file-btn"
                  style="position: absolute"
                  @click="clearImg"
                ></i>
                <img
                  :src="imageUrl ? imageUrl : playlistObj.image_path"
                  class="w-100 my-4"
                  alt="post-image"
                />
              </div>
              <div v-if="errorMessage" class="text-danger">
                {{ errorMessage }}
              </div>
            </div>
            <div>
              <input
                type="file"
                v-show="false"
                id="image"
                ref="image"
                accept="image/*"
                @change="handleFile"
              />
              <button
                type="button"
                class="btn btn-outline-primary d-flex align-items-center justify-content-center rounded-pill px-5 w-100"
                @click="$refs.image.click()"
              >
                <i class="bi bi-file-image text-success fs-5 me-1"> </i>
                <span class="post-text">Add playlist image</span>
              </button>
            </div>
            <div class="modal-footer border-0 justify-content-center py-3">
              <button
                type="button"
                class="btn cancel-btn px-5 py-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn submit-btn px-5 py-2 ms-1 ms-lg-3"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"

export default {
  name: "PlaylistModal",
  props: {
    playlist: Object,
    method: {
      type: String,
      default: "create"
    }
  },
  data() {
    return {
      playlistObj: null,
      imageUrl: null,
      maxFileSize: 100 * 1024 * 1024, // 100MB
      errorMessage: null
    }
  },
  created() {
    this.playlistObj = {
      name: this.playlist.name,
      description: this.playlist.description,
      image_path: this.playlist.image_path
    }
  },
  mounted() {
    let modal = this.$refs.playlistModal
    modal.addEventListener("hidden.bs.modal", () => {
      this.closeModal()
    })
    modal.addEventListener("shown.bs.modal", () => {
      let textarea = this.$refs.playlistDescription
      textarea.style.height = `${textarea.scrollHeight}px`
    })
  },
  computed: {
    ...mapState("user", ["user"])
  },
  methods: {
    ...mapActions("playlist", ["createPlaylist", "editPlaylist"]),
    closeModal() {
      this.imageUrl = null
      this.errorMessage = null
      this.$emit("reset-default")
    },
    handleFile(e) {
      let file = e.target.files[0]

      if (file.size > this.maxFileSize) {
        this.errorMessage = "File is too large. Cannot exceed 100MB"
        return
      }

      if (file.type.match("image.*")) {
        this.playlistObj.image_path = file
        this.imageUrl = URL.createObjectURL(file)
      }
      this.errorMessage = null
    },
    submitPlaylist() {
      if (!this.playlistObj.image_path) {
        this.errorMessage = "You need to select an image"
        return
      }
      let formData = new FormData()
      formData.append("name", this.playlistObj.name)
      formData.append("description", this.playlistObj.description)
      formData.append("image_path", this.playlistObj.image_path)

      if (this.method == "create") {
        formData.append("user_id", this.user.id)
        this.createPlaylist(formData)
        this.$refs.closeButton.click()
      } else {
        formData.append("user_id", this.playlist.user.id)
        formData.append("id", this.playlist.id)
        this.editPlaylist(formData)
        this.$refs.closeButton.click()
      }
    },
    resize(event) {
      event.target.style.height = "auto"
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    clearImg() {
      this.imageUrl = null
      this.playlistObj.image_path = null
    }
  },
  watch: {
    playlist(data) {
      this.playlistObj = data
    }
  }
}
</script>

<style scoped>
.cancel-btn {
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 52px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
}
.submit-btn {
  background: linear-gradient(180deg, #ff411f 0%, #ff0bb9 100%);
  border-radius: 52px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
}

.close-file-btn {
  top: 30px;
  right: 9px;
  cursor: pointer;
  color: #ffc107;
}

.close-file-btn:hover {
  color: #adb5bd;
}
</style>
