<template>
  <div>
    <login-modal ref="loginModal"></login-modal>
    <div
      class="d-flex flex-wrap justify-content-between align-items-center px-2 px-md-0 pt-5 pt-lg-3"
    >
      <div>
        <div class="input-group">
          <label class="input-group-text">
            <i class="bi bi-search fs-6" @click="search"></i>
          </label>
          <input
            id="playlistSearch"
            type="text"
            class="form-control border-start-0"
            :class="{ 'border-end-0': searchTerm }"
            placeholder="Search for playlists..."
            v-model="searchTerm"
            @keyup.enter="search"
          />
          <label class="input-group-text" v-if="searchTerm">
            <i class="bi bi-x fs-6" @click="clearSearch"></i>
          </label>
        </div>
      </div>
      <button
        type="button"
        class="btn btn-create text-white my-3 px-4"
        @click="openPlaylistModal"
      >
        Create Playlist
      </button>
    </div>

    <playlist-modal
      :playlist="editedPlaylist"
      :method="playlistMethod"
      @reset-default="closePlaylistModal"
      ref="playlistModal"
    ></playlist-modal>
    <div v-if="playlists" class="row">
      <div
        class="col-sm-6 col-md-4 col-lg-3"
        v-for="playlist in playlists"
        :key="playlist.id"
      >
        <playlist-card :playlist="playlist"></playlist-card>
      </div>
    </div>
    <div v-if="playlists.length == 0" class="pt-4">
      <b>No playlist found</b>
    </div>
    <button
      type="button"
      class="btn btn-outline-primary my-4"
      @click="loadNextPage"
      v-if="page.last_page > page.current_page"
    >
      See more playlists
      <i class="bi bi-arrow-up-right-square-fill"></i>
    </button>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { Modal } from "bootstrap"
import PlaylistCard from "@/components/playlist/PlaylistCard.vue"
import PlaylistModal from "@/components/dashboard/playlist/PlaylistModal.vue"
import LoginModal from "@/components/LoginModal.vue"
import { isAuth } from "@/utils/misc"
import { SET_PLAYLIST, SET_TRACK } from "@/utils/mutation-types"

export default {
  name: "PlaylistFeeds",
  components: { PlaylistCard, PlaylistModal, LoginModal },
  data() {
    const editedPlaylist = {
      name: "",
      description: "",
      image_path: ""
    }
    return {
      playlistMethod: "create",
      editedPlaylist,
      defaultPlaylist: Object.freeze(Object.assign({}, editedPlaylist)),
      searchTerm: ""
    }
  },
  async mounted() {
    await this.getPlaylists({ page: 1, search: "" })
    this.setPlaylist(null)
    this.setTrack(null)
  },
  computed: {
    ...mapState("playlist", ["playlists", "page"])
  },
  methods: {
    ...mapActions("playlist", ["getPlaylists"]),
    ...mapMutations("playlist", { setPlaylist: SET_PLAYLIST }),
    ...mapMutations("track", { setTrack: SET_TRACK }),
    loadNextPage() {
      this.getPlaylists({ page: this.page.current_page + 1, search: "" })
    },
    openPlaylistModal() {
      if (!isAuth()) {
        this.openLoginModal()
        return
      }

      const modal = new Modal(this.$refs.playlistModal.$refs.playlistModal)
      modal.show()
    },
    closePlaylistModal() {
      this.editedPlaylist = Object.assign({}, this.defaultPlaylist)
    },
    openLoginModal() {
      const modal = new Modal(this.$refs.loginModal.$refs.loginModal)
      modal.show()
    },
    clearSearch() {
      this.searchTerm = ""
    },
    search() {
      this.getPlaylists({ page: 1, search: this.searchTerm })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
.btn-create {
  background: $primary-gradient;
  border-radius: 26px;
}

.input-group {
  width: 22rem;
}
</style>
